import React from "react"
import IntegrationTemplate from "../../templates/integration"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import IntegrationStorageContent from 'components/content/integration-storage'
import { meta } from '../../configs/storage-providers';

const ContentPage = () => (
  <IntegrationTemplate
    key="aws"
    name="Amazon (AWS) Glacier"
    shortDescription="Save your backups on AWS Glacier Storage."
    website={"https://aws.amazon.com/s3/"}
    links={[{text: 'Documentation', url: '/docs'}]}
    category="Storage"
    logo="aws-glacier.png"
    metaTitle={meta.title.replace('##provider##', 'AWS Glacier')}
    metaDescription={meta.description.replace('##provider##', 'AWS Glacier')}
  >
    <IntegrationStorageContent
      keyRef="aws-glacier"
      service="AWS Glacier"
      provider="AWS"
    />
  </IntegrationTemplate>
)

export default ContentPage
